import React from 'react';
import styled from 'styled-components';
import { Box, Text } from 'rebass';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Label = styled.label`
  height: 21px;
  padding-top: 9px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.58rem;
  letter-spacing: 0.3px;
  color: #032e4f;
`;

const Input = styled.input`
  display: block;
  // width: 300px;
  width: 100%;
  // max-width: 500px;
  height: 38px;
  padding-top: 5px;
  padding-left: 5px;
  border-radius: 4px;
  border: solid 1px ${props => (props.error ? '#dd5353' : '#cfd4dc')};
  background-color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.84;
  letter-spacing: 0.6px;
  color: #032e4f;
`;

export const Input2 = styled.input`
  width: 70%;
  height: 40px;
  border-radius: 4px 0 0 4px;
  border: solid 1px #cfd4dc;
  background-color: #ffffff;
  margin-left: 24px;
`;

// const Error = styled.div`
//   height: 20px;
//   font-family: 'PF BeauSans Pro';
//   font-size: 13px;
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.48rem;
//   letter-spacing: normal;
//   color: #dd5353;
// `;

export const FormInput = React.forwardRef(
  (
    {
      title,
      name,
      defaultValue,
      type = 'text',
      required,
      compact = false,
      width,
      ...rest
    },
    ref
  ) => {
    const { errors } = useFormContext();
    const { t } = useTranslation();

    return (
      <Box pb={compact ? '9px' : '19px'} width={width}>
        {title && (
          <Label htmlFor={name}>
            {title}
            {required ? ' *' : ''}
          </Label>
        )}
        <Input
          name={name}
          type={type}
          defaultValue={defaultValue}
          error={errors[name]}
          {...rest}
          ref={ref}
        />
        {errors[name] && (
          <Text pt="4px" fontSize="13px" lineHeight="1.48" color="pastelRed">
            {errors[name].type === 'required'
              ? t('validation.required')
              : errors[name].message}
          </Text>
        )}
      </Box>
    );
  }
);
