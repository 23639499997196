import React from 'react';
import useForm, { FormContext, useFormContext } from 'react-hook-form';

import { FormInput } from './Input';

export default function Form({ onSubmit, children, ...rest }) {
  const methods = useForm();

  const { handleSubmit } = methods;

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} {...rest}>
        {children}
      </form>
    </FormContext>
  );
}

export function Field(props) {
  const { values, erros } = useFormContext();
  return <FormInput {...props} />;
}
