import React, { useState } from 'react';
import { Box, Button, Image, Text, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { useIdentityContext } from 'react-netlify-identity';
import ScaleLoader from "react-spinners/ScaleLoader";
import { useFormContext } from 'react-hook-form'
import { navigate } from 'gatsby';

import SEO from '../components/seo';
import { Layout } from '../layout';
import { Container } from '../components/container';
import { Header } from '../components/Header';
import Alert from '../components/Alert';
import Form, { Input } from '../components/Form';

import EnvelopeIcon from '../icons/envelope.svg';
import i18n from '../i18n';

// const Input = styled.input`
//   width: 100%;
//   max-width: 100%;
//   height: 40px;
//   padding: 0 10px;
//   border-radius: 4px;
//   border: solid 1px ${props => props.theme.colors.lightBlueGrey};
//   background-color: #ffffff;
//   border-radius: 4px 0 0 4px;
// `;

const AccountRecoveryForm = ({loadingIndicator})=>{
  const { register } = useFormContext()
  
  const { t } = useTranslation();
  
  return (<Flex
      pt="60px"
      width={[1, '600px']}
      flexWrap="wrap"
      mx="auto"
      flexDirection="row"
    >
      <Box width={[1, 1 / 2]} paddingBottom={[0, 0]}>
        <Input
          compact
          placeholder={t('account_recovery.placeholder')}
          width={[1, 1]}
          name="recoveryEmail"
          css={{
            backgroundColor: '#ffffff',
            borderRadius: '4px 0 0 4px',
            height: "40px",
          }}
          ref={register({
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: t('validation.invalid_email_address'),
            },
          })}
        />
      
      </Box>
      <Box width={[1, 1 / 2]} >
      { loadingIndicator ?
        (<Box textAlign="center" width="100px"> 
          <ScaleLoader
            css={{}}
            size={40}
            color={"#01549b"}
            loading={loadingIndicator}
          />
        </Box>) :
        (<Button
          py="8px"
          bg="blues.peacock"
          css={{ borderRadius: '0 4px 4px 0' }}
          width={[1]}         
        >
        {t('account_recovery.button')}
        </Button>)
      }
      </Box>
    </Flex>)
}

const AccountRecoveryPage = props => {
  const { t } = useTranslation();
  const [alertMsg, setAlertMsg] = useState(null);
  const { requestPasswordRecovery } = useIdentityContext();
  const [loadingIndicator, setLoadingIndicator] = useState(false);

  const onSubmit = async(data)=>{
    const recoveryEmail = data.recoveryEmail
    setLoadingIndicator(true)
    try{
      setAlertMsg(null)
      const res = await requestPasswordRecovery(recoveryEmail);
      setLoadingIndicator(false)
      navigate((i18n.language === 'en')? `${'/'+i18n.language}/sign_in`: '/sign_in');
    }catch(e){
      setLoadingIndicator(false)
      setAlertMsg('Invalid email entered')
    }
  }
  
  return (
    <Layout {...props}>
      <SEO title="ACCOUNT RECOVERY" keywords={['weboxit', 'boxit']} />
      <Header
        title={t('account_recovery.title')}
        subtitle={t('account_recovery.subtitle')}
      />
      <Container>
        <Box pt="70px" mx="auto">
            <Alert message={alertMsg} />
        </Box>
        <Box pt="70px" width="74px" mx="auto">
          <Image mx="auto" src={EnvelopeIcon} />{' '}
        </Box>
        <Form onSubmit={onSubmit}  >
          <AccountRecoveryForm 
            loadingIndicator={loadingIndicator} 
          />
        </Form>
        <Text py="56px" fontSize="20px" textAlign="center" color="marine">
          {t('account_recovery.please_enter_your_email')}
          <br />
          {t('account_recovery.thank_you')}
        </Text>
      </Container>
    </Layout>
  );
};

export default AccountRecoveryPage;
