import React from 'react';
import { Box, Flex, Image, Text } from 'rebass';

import AttentionIcon from '../../icons/attention.svg';

const Alert = ({ message, type }) => {

  const isSuccess = type && type === 'success';

  return (
    message && (
      <Box
        fontSize="13px"
        px="25px"
        py="12px"
        css={{
          border: isSuccess?'1px solid #01549b':'1px solid #dd5353',
          borderRadius: '5px',
        }}
      >
        <Flex flexDirection="row" alignItems="flex-start">
          {isSuccess ? null : <Image
            src={AttentionIcon}
            pr="12px"
          />}
          <Flex flexDirection="column">
            {message.constructor === Array ? (
              message.map(str => <Text color={isSuccess? 'blues.peacock': "pastelRed"}>{message}</Text>)
            ) : (
              <Text  color={isSuccess? 'blues.peacock': "pastelRed"}>{message}</Text>
            )}
          </Flex>
        </Flex>
      </Box>
    )
  );
};

export default Alert;
